import React from 'react'
import { format } from 'date-fns'

import env from 'env'

export default function Blog({posts, blogUrl = env.BLOG_URL}) {
  return (
    <div className="bg-gray-200 py-16">
      <div className="container max-w-5xl">
        <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
          Our Food Truck Catering Blog
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
          {posts.map(({id, date_modified, image, title, url}) => (
            <div key={id} className="flex flex-col">
              <div className="flex-1">
                <div className="bg-cover h-0" alt="" style={{paddingTop:"60%", backgroundImage: `url(${image || burger})`, borderRadius: "1.5rem"}}/>
                <a className="text-xl text-black font-bold my-2" href={url} dangerouslySetInnerHTML={{ __html: title }} />
              </div>
              <div>
                <p className="text-md font-light my-2">
                  {format(new Date(date_modified), 'MMMM do, yyyy')}
                </p>
                <a href={url} children="Read More >" className="text-blue text-md" />
              </div>
            </div>
          ))}
        </div>
        <div className="mt-10 text-center">
          <a href={blogUrl} className="text-blue">
            Learn About Food Truck Events
          </a>
        </div>
      </div>
    </div>
  )
}