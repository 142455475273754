import React, { useState } from 'react'
import AutoComplete from 'app/theme/forms/AutoComplete'
// import CityAutoComplete from 'app/theme/forms/CityAutoComplete'
import { ChevronRight, MapPin } from 'react-feather'
import { useClient } from 'jsonapi-react'

// Images
import atlanta from 'assets/img/atlanta.jpg'
import chicago from 'assets/img/chicago.jpg'
import dallas from 'assets/img/dallas.jpg'
import losAngeles from 'assets/img/los-angeles.jpg'
import sanJose from 'assets/img/san-jose.jpg'
import washington from 'assets/img/washington-dc.jpg'

const cityData = [
  {
    link: '/los-angeles-ca/food-truck-catering/',
    image: losAngeles,
    name: 'Los Angeles',
    eaterCount: '408k+',  
    foodTruckCount: 1160,
  },
  {
    link: '/san-jose-ca/food-truck-catering/',
    image: sanJose,
    name: 'San Jose',
    eaterCount: '109k+',  
    foodTruckCount: 243,
  },
  {
    link: '/chicago-il/food-truck-catering/',
    image: chicago,
    name: 'Chicago',
    eaterCount: '85k+', 
    foodTruckCount: '276',
  },
  {
    link: '/atlanta-ga/food-truck-catering/',
    image: atlanta,
    name: 'Atlanta',
    eaterCount: '111k+',
    foodTruckCount: 417,
  },
  {
    link: '/washington-dc/food-truck-catering/',
    image: washington,
    name: 'Washington, D.C.',
    eaterCount: '119k+',
    foodTruckCount: 297,
  },
  {
    link: '/dallas-tx/food-truck-catering/',
    image: dallas,
    name: 'Dallas',
    eaterCount: '104k+',
    foodTruckCount: 419,
  }
]

export default function FindATruck() {
  const [selectedCity, setSelectedCity]  = useState()
  let foodTruckCityUrl = '/food-trucks/'

  if (selectedCity && selectedCity.urlSlug) {
    foodTruckCityUrl = `${foodTruckCityUrl}${selectedCity.url_slug}/`
  }

  return (
    <div className="bg-white py-16">
      <div className="container max-w-5xl">
        <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
          Food Truck Catering Near You
        </h2>
        <p className="text-black text-xl text-left lg:text-center font-light py-4">
          We drive delicious eats to your door — you just have to sit back and enjoy!
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
          <CityStats data={cityData} />
        </div>
        <div className="container flex flex-col items-center justify-center text-center my-10">
          <p className="text-black text-2xl">
            Browse high-quality, Roaming Hunger certified food trucks near you.
          </p>
          <div className="flex mt-5 bg-white border border-blue-400 rounded-xl overflow-hidden" >
            <CityAutoComplete
              value={selectedCity}
              onChange={city => {
                setSelectedCity(city)
              }}
            />
            <a
              href={foodTruckCityUrl}
              className={["btn px-4 py-2 text-lg text-white border-0 w-20", (selectedCity ? "btn-blue" : "bg-gray-600 pointer-events-none")]}
              onClick={() => {
                // TODO: Show faux-error message
              }}
            >
              Go<ChevronRight className="inline" />
            </a>
          </div>
          <p className="text-gray-600 text-lg font-light mt-5">
            Or browse our <a className="text-blue font-bold" href="/food-trucks/">full list of cities</a>
          </p>
        </div>
      </div>
    </div>
  )
}

function CityStats({data}){
  return (
    data.map((city, index) => <CityStatsCard key={index} {...city} />)
  )
}

function CityStatsCard({link, image, name, eaterCount = 0, foodTruckCount = 0}) {
  return (
    <a href={link}>
      <div 
        className="bg-cover h-0" 
        alt="" 
        style={{
          paddingTop:"50%", 
          backgroundImage: `url(${image})`, 
          borderTopRightRadius: "1.5rem",
          borderTopLeftRadius: "1.5rem"
        }}
      />
      <div 
        className="bg-gray-200 text-center"
        style={{
          borderBottomRightRadius: "1.5rem",
          borderBottomLeftRadius: "1.5rem"
        }}
      >
        <p className="text-2xl text-black font-bold py-2">{name}</p>
        <div className="flex justify-between p-4 pt-0 text-black">
          <p className="text-sm font-light">
            <ForkAndSpoonImage className="inline mr-1" />
            <span className='font-bold mr-1'>{eaterCount}</span>
            Eaters
          </p>
          <p className="text-sm font-light">
            <FoodTruckImage className="inline mr-1"/>
            <span className='font-bold mr-1'>{foodTruckCount}</span>
            Food Trucks
          </p>
        </div>
      </div>
    </a>
  )
}

function FoodTruckImage({className}) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">)
      <path style={{stroke: '#03ABEC', strokeLinecap: 'round', strokeLinejoin: 'round'}} d="M9 17C9 17.5304 8.78929 18.0391 8.41421 18.4142C8.03914 18.7893 7.53043 19 7 19C6.46957 19 5.96086 18.7893 5.58579 18.4142C5.21071 18.0391 5 17.5304 5 17C5 16.4696 5.21071 15.9609 5.58579 15.5858C5.96086 15.2107 6.46957 15 7 15C7.53043 15 8.03914 15.2107 8.41421 15.5858C8.78929 15.9609 9 16.4696 9 17ZM9 17H15M15 17C15 17.5304 15.2107 18.0391 15.5858 18.4142C15.9609 18.7893 16.4696 19 17 19C17.5304 19 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17C19 16.4696 18.7893 15.9609 18.4142 15.5858C18.0391 15.2107 17.5304 15 17 15C16.4696 15 15.9609 15.2107 15.5858 15.5858C15.2107 15.9609 15 16.4696 15 17Z" />
      <path style={{stroke: '#03ABEC', strokeLinecap: 'round', strokeLinejoin: 'round'}} d="M19 17H20C20.2652 17 20.5196 16.8946 20.7071 16.7071C20.8946 16.5196 21 16.2652 21 16V11.472C21 11.1617 20.9277 10.8556 20.789 10.578L19.829 8.658C19.5798 8.15979 19.1968 7.7408 18.723 7.44797C18.2491 7.15513 17.7031 7.00002 17.146 7H6C5.20435 7 4.44129 7.31607 3.87868 7.87868C3.31607 8.44129 3 9.20435 3 10V16C3 16.2652 3.10536 16.5196 3.29289 16.7071C3.48043 16.8946 3.73478 17 4 17H5M3 12H21M15 12V7M6 5.5C6 5.10218 6.15804 4.72064 6.43934 4.43934C6.72064 4.15804 7.10218 4 7.5 4H14.5C14.8978 4 15.2794 4.15804 15.5607 4.43934C15.842 4.72064 16 5.10218 16 5.5C16 5.89782 15.842 6.27936 15.5607 6.56066C15.2794 6.84196 14.8978 7 14.5 7H7.5C7.10218 7 6.72064 6.84196 6.43934 6.56066C6.15804 6.27936 6 5.89782 6 5.5Z" />
    </svg>
  )
}

function ForkAndSpoonImage({className}) {
  // Optimized svg with https://www.svgviewer.dev/
  return (
    <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path style={{ fill: '#03ABEC' }} d="M13.688 2.438a.56.56 0 0 1 .557.486L14.25 3v12a.562.562 0 0 1-1.12.076L13.125 15v-3.75h-1.687a.56.56 0 0 1-.558-.486l-.005-.076V5.25a2.81 2.81 0 0 1 2.813-2.812m-4.5 0a.56.56 0 0 1 .557.486L9.75 3v3a3 3 0 0 1-2.437 2.948V15a.562.562 0 0 1-1.12.076L6.188 15V8.948a3 3 0 0 1-2.434-2.792L3.75 6V3a.562.562 0 0 1 1.12-.076L4.875 3v3c0 .84.552 1.55 1.313 1.79V3a.562.562 0 0 1 1.12-.076L7.312 3v4.789a1.88 1.88 0 0 0 1.307-1.65L8.625 6V3a.563.563 0 0 1 .563-.562m3.937 7.687V3.659a1.69 1.69 0 0 0-1.12 1.462L12 5.25v4.875z" />
    </svg>
  )
}

function CityAutoComplete({filter, ...rest}) {
  const client = useClient()
  
  return (
    <AutoComplete
      {...rest}
      CustomIcon={MapPin}
      placeholder="Search by City"
      getResults={term => {
        if (term.length < 1) {
          return null
        }
        return client.fetch(['cities', {
          filter: {
            search: term,
            ...filter,
          },
          page: {
            size: 10,
          }
        }]).then(res => {
          return res.data
        })
      }}
      getLabel={city => (
        `${city.display_name || city.name}`
      )}
    />
  )
}