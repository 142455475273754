import React from 'react'
import styles from './HowItWorksCustom.module.css'
import { ChevronRight } from 'react-feather'

const defaultItems = [
  { title: '', description: 'Share your event details with us.' },
  { title: '', description: 'We send you handpicked recommendations from over 20,000 food truck partners.' },
  { title: '', description: 'We handle all the little details (and scary unknowns) and protect you with Roaming Hunger’s Worry-Free Guarantee.' },
]

export default function HowItWorksCustom(props) {
  const {
    heading = 'How to Book with Us',
    className,
    description,
    buttonText = 'Get Started',
    buttonUrl,
    onButtonClick,
    image,
    items = defaultItems,
    hiddenLg = true,
    ...rest
  } = props

  const Description = Object.prototype.toString.call(description) === 'string' ? <p>{description}</p> : description
  
  return (
    <section
      {...rest}
      className={[
        className,
        'bg-gray-200 text-black py-16',
        hiddenLg && 'lg:hidden'
      ]}
    >
      <div className='container max-w-5xl grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2'>
        <div className="order-1 md:order-0">
          <h2 className="font-platform mb-2 text-3xl lg:text-4xl">
            {heading}
          </h2>
          {
            description && Description
          }
          <div className='my-6'>
            {items.map(({title, description}, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.circle}>
                  {index + 1}
                </div>
                <div className="">
                  {title && <h3>{title}</h3>}
                  {description && <p>{description}</p>}
                </div>
              </div>
            ))}
          </div>
          {onButtonClick ? (
            <button
              type="button"
              className={['btn', styles.button, 'btn btn-blue border-0 m-auto mb-2 px-4 py-2 rounded-full']}
              onClick={onButtonClick}
            >{buttonText} <ChevronRight className='inline' /></button>
          ) : buttonUrl ? (
            <a
              href={buttonUrl}
              className={['btn', styles.button, 'btn btn-blue border-0 m-auto mb-2 px-4 py-2 rounded-full']}
            >{buttonText} <ChevronRight className='inline' /></a>
          ) : null}
        </div>
        {
          image &&
          <img src={image.url} alt={image.altText} className='rounded-xl md:order-1'/>
        }
      </div>
    </section>
  )
}
