import React from 'react'
import { ChevronRight } from 'react-feather'
import computer from 'assets/svg/computer.svg'
import partyHat from 'assets/svg/party-hat.svg'
import graduationCap from 'assets/svg/graduation-cap.svg'
import filmCamera from 'assets/svg/film.svg'
import weddingCake from 'assets/svg/wedding-cake.svg'
import balloons from 'assets/svg/balloons.svg'

const eventIcons = {
  'corporate': computer,
  'private': partyHat,
  'campus': graduationCap,
  'production': filmCamera,
  'wedding': weddingCake,
  'community': balloons,
}

const events = [
  {
    description: 'Catered Food Truck Lunch Programs, In-Office Meal Programs, Business Park Lunches, and Employee Appreciation',
    image: {
      src: eventIcons.corporate,
      altText: 'An illustration of a computer'
    },
    link: {
      url: '/food-truck-catering/corporate-catering/',
      text: 'Corporate Catering'
    }
  },
  {
    description: 'Food Trucks on Campus, Student Organizations, On/Off-Campus Events, Graduation, Prom, and School Dances',
    image: {
      src: eventIcons.campus,
      altText: 'An illustration of a graduation cap'
    },
    link: {
      url: '/food-truck-catering/campus-catering/',
      text: 'University & School Events'
    }
  },
  {
    description: 'Expos, Professional Events, Industry Events, and Marketing Events',
    image: {
      src: eventIcons.community,
      altText: 'An illustration of balloons'
    },
    link: {
      url: '/food-truck-catering/conference-and-event-catering/',
      text: 'Conferences & Conventions'
    }
  },
  {
    description: 'Production Catering, Movie Set Catering, TV and Film Catering, 24 Hour Production Catering, and On-Location Catering',
    image: {
      src: eventIcons.production,
      altText: 'An illustration of a film camera'
    },
    link: {
      url: '/food-truck-catering/film-production-catering/',
      text: 'Production Catering'
    }
  },
  {
    description: 'Community Events, Street Fairs, Neighborhood Block Parties, Flea Markets, and Little League Games',
    image: {
      src: eventIcons.community,
      altText: 'An illustration of balloons'
    },
    link: {
      url: '/food-truck-catering/large-event-food-concessions/',
      text: 'Large-Scale Events'
    }
  },
  {
    description: 'Private Parties, Birthday, Holiday, Graduation, Anniversary, Bar/Bat Mitzvahs, Baby Shower, and House-Warming',
    image: {
      src: eventIcons.private,
      altText: 'An illustration of a party hat'
    },
    link: {
      url: '/food-truck-catering/party-catering/',
      text: 'Private Parties'
    }
  }
]

export default function EventTypes({openModal}) {

  return (
    <div className="bg-white py-16">
      <div className="container max-w-5xl">
        <div className='m-auto lg:w-3/5'>
          <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
            Top Events for Food Truck Catering
          </h2>
          <p className="text-black text-xl text-left lg:text-center font-light py-4">
            Help your community join together, for anything ranging from corporate lunches 
            to food festivals, with help from our expert concierge team.
          </p>
        </div>
        <Events events={events} />
        <div className="text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
            <div className='text-black'>
              <h3 className='text-4xl'>Want an Amazing Food Truck Experience?</h3>
              <p className='my-2 text-xl font-light'>We'll do the planning so you can relax!</p>
            </div>
            <button
              onClick={openModal}
              className="btn btn-blue border-0 m-auto px-4 py-2 rounded-full block"
              >
                Get Started <ChevronRight className='inline' />
            </button>
          </div>
      </div>
    </div>
  )
}

function Events({events}) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
      {
        events.map((item, index) => (
          <Event key={index} {...item} />
        ))
      }
    </div>
  )
}

function Event({description, image, link}) {
  return (
    <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
      <div className="flex-1">
        <img src={image.src} alt={image.altText} className="m-auto h-20 w-auto"/>
        <a href={link.url} className="text-2xl text-black font-bold my-2">
          {link.text}
        </a>
        <p className="text-md font-light my-2">
          {description}
        </p>
      </div>
      <a href={link.url} children="Explore >" className="text-blue text-md" />
    </div>
  )
}