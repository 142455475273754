import React from 'react'
import guarantee from 'assets/svg/guarantee.svg'
import { ChevronRight } from 'react-feather'
import styles from './Guarantee.module.css'

const defaultSubHeading = 'Your food truck catering event is protected by the 100% Roaming Hunger Service Guarantee.'
const defaultItems = [
  'Roaming Hunger holds all caterer payments in escrow to guarantee you get fantastic day-of service.',
  'Roaming Hunger protects you in the event of a cancellation and finds you a replacement caterer or your money back.',
  'Roaming Hunger\'s Experience Managers are with you through your entire experience — so when stuff happens, they\'re on it.'
]

export default function Guarantee(props) {
  const {
    heading = 'Why Work With Us?',
    subheading = defaultSubHeading,
    buttonText = 'Get Started',
    buttonUrl,
    items = defaultItems,
    padding = 'py-16',
    placeName,
    onButtonClick,
    ...rest
  } = props

  let actionButton

  if (onButtonClick) {
    actionButton = (
      <button
        type="button"
        className="btn btn-white text-blue mt-5 border-0 m-auto px-4 py-2 rounded-full"
        onClick={onButtonClick}
      >
        Get Started <ChevronRight className='inline' />
      </button>
    )
  }
  else if (buttonUrl) {
    actionButton = (
      <a
        href={buttonUrl}
        className="btn btn-white text-blue mt-5 border-0 m-auto px-4 py-2 rounded-full"
      >
        Get Started <ChevronRight className='inline' />
      </a>
    )
  }

  return (
    <section className='bg-green text-white py-16' {...rest}>
      <div className='container max-w-5xl grid md:grid-cols-2 grid-cols-1 md:gap-16 grid-gap-2'>
        <div className="bg-green-200 flex align-center w-auto rounded-xl py-8">
          <img src={guarantee} alt="An illustration of a verified badge" className="w-auto h-56 m-auto" />
        </div>
        <div className="flex-1">
          <h2 className="font-platform text-5xl font-bold leading-tight">
            Our Worry-Free Guarantee
          </h2>
          <p className="text-xl font-light py-4">
            Booking food truck catering with Roaming Hunger means fast expert-service, flexibility, 
            and a worry-free guarantee from start to finish. Every food truck, every time. {' '}
            <a className='underline' href='/food-truck-catering/roaming-hunger-guarantee/'>Click here for more details!</a>
          </p>
          { items && <ItemList items={items} /> }
          {
            actionButton && <div className="mt-4">
              { actionButton }
            </div>
          }
        </div>
      </div>
    </section>
  )
}

function ItemList({items}) {
  return (
    <ul className='leading-none'>
      {
        items.map((item, index) => <Item key={index} text={item} />)
      }
    </ul>
  )
}

function Item({text}) {
  return (
    <li className={[styles.item]}>
      <Checkbox parentStyle='w-8 h-auto mr-4' fill='#BFEEE2' />
      <span>{text}</span>
    </li>
  )
}

function Checkbox({parentStyle, fill, stroke}) {
  return (
    <svg className={parentStyle} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path fill={fill} stroke={stroke} d="M10.709 20.734q2.04 0 3.838-.78a10.2 10.2 0 0 0 3.183-2.159 10.2 10.2 0 0 0 2.159-3.174 9.6 9.6 0 0 0 .78-3.847q0-2.041-.78-3.838a10.2 10.2 0 0 0-2.158-3.184 10.2 10.2 0 0 0-3.184-2.158 9.6 9.6 0 0 0-3.848-.781 9.6 9.6 0 0 0-3.847.78 10.2 10.2 0 0 0-3.174 2.159 10.3 10.3 0 0 0-2.149 3.184 9.5 9.5 0 0 0-.781 3.838q0 2.04.781 3.847a10.2 10.2 0 0 0 2.159 3.174 10.2 10.2 0 0 0 3.173 2.158 9.6 9.6 0 0 0 3.848.781M9.615 15.54a1 1 0 0 1-.459-.107 1.3 1.3 0 0 1-.4-.362l-2.451-3.007a1.6 1.6 0 0 1-.176-.303 1 1 0 0 1-.059-.332q0-.332.225-.567a.73.73 0 0 1 .557-.244q.215 0 .38.088.177.089.362.332l1.982 2.559 4.17-6.7q.274-.449.703-.449.313 0 .567.205.254.196.254.538 0 .165-.079.332-.078.165-.166.312l-4.59 7.236a1.1 1.1 0 0 1-.36.352.9.9 0 0 1-.46.117"/>
    </svg>
  )
}
