export const googleMetaTags = 
  {
    "@context": "http://schema.org",
    "@type": "Organization",
    "contactPoint": {
        "@type": "ContactPoint",
        "contactOption": "TollFree",
        "availableLanguage": "en-US",
        "contactType": "Sales",
        "telephone": "+1-800-568-9370",
        "email": "hungry@roaminghunger.com",
        "name": "Roaming Hunger Contact Point",
        "url": "https://roaminghunger.com",
        "@id": "https://roaminghunger.com/#ContactPoint"
    },
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "10951 W Pico Blvd, Ste 300",
        "postalCode": "90064",
        "addressRegion": "CA",
        "addressLocality": "Los Angeles",
        "addressCountry": "US",
        "name": "Roaming Hunger Postal Address",
        "url": "https://roaminghunger.com",
        "@id": "https://roaminghunger.com/#PostalAddress"
    },
    "foundingDate": "2009-08-08T00:00:00+0000",
    "knowsLanguage": "en-US",
    "url": "https://roaminghunger.com",
    "description": "Find and book your favorite food trucks and food carts with Roaming Hunger, the hub for all things street food and catering. Use the interactive city map to find out where your next meal is parked or book a tasty vendor for your next event.",
    "legalName": "Roaming Hunger, LLC ",
    "sameAs": [
        "https://www.linkedin.com/company/roaming-hunger/",
        "https://www.crunchbase.com/organization/roaming-hunger",
        "https://www.yelp.com/biz/roaming-hunger-belmont",
        "https://www.secure.instagram.com/roaminghunger/?hl=fb-ha",
        "https://www.bbb.org/us/ca/west-hollywood/profile/food-trucks/roaming-hunger-1216-886904",
        "https://www.facebook.com/roaminghunger/",
        "https://en.wikipedia.org/wiki/Roaming_Hunger",
        "https://twitter.com/roaminghunger?lang=en"
    ],
    "name": "Roaming Hunger",
    "knowsAbout": [
        "https://www.wikidata.org/wiki/Q3303463",
        "https://www.wikidata.org/wiki/Q6887072"
    ],
    "logo": "https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/logo-bg.dca95380.svg",
    "email": "hungry@roaminghunger.com",
    "telephone": "+1-800-568-9370",
    "image": "https://d3hbe0kmbam4a5.cloudfront.net/assets/static/media/homepage-carousel-3.ff88590d.jpg",
    "@id": "https://roaminghunger.com/#Organization"
  }
