import burger from 'assets/svg/burger.svg'
import pizza from 'assets/svg/pizza.svg'
import taco from 'assets/svg/taco.svg'
import barbecue from 'assets/svg/fork-sausage.svg'
import dessert from 'assets/svg/shaved-ice.svg'
import asian from 'assets/svg/sushi.svg'
import sandwiches from 'assets/svg/grilled-cheese.svg'
import mediterranean from 'assets/svg/pita-wrap.svg'
import healthy from 'assets/svg/carrots.svg'
import beverages from 'assets/svg/drink.svg'
import breakfast from 'assets/svg/muffin.svg'

export default {
  'pizza': pizza,
  'burgers': burger,
  'tacos': taco,
  'bbq': barbecue,
  'dessert': dessert,
  'asian': asian,
  'sandwiches': sandwiches,
  'mediterranean': mediterranean,
  'healthy-food': healthy,
  'beverages': beverages,
  'breakfast': breakfast
}
