import React, { useEffect, useState } from 'react'
import { useClient } from 'jsonapi-react'
import AutoComplete from 'app/theme/forms/AutoComplete'

export default function CityAutoComplete(props) {
  const client = useClient()
  const { filter, ...rest } = props

  return (
    <AutoComplete
      {...rest}
      placeholder="Search by City"
      getResults={term => {
        if (term.length < 1) {
          return null
        }
        return client.fetch(['cities', {
          filter: {
            search: term,
            ...filter,
          },
          page: {
            size: 10,
          }
        }]).then(res => {
          return res.data
        })
      }}
      getLabel={city => (
        `${city.display_name || city.name}`
      )}
    />
  )
}


