import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from 'app/theme/layout'
import { Helmet } from 'react-helmet-async'
import { useQuery } from 'jsonapi-react'
import posthog from 'posthog-js'
import { useFeatureFlagVariantKey } from 'lib/posthog'
import { Star } from 'react-feather'
import { format } from 'date-fns'

import { googleMetaTags } from './HomePageSchema'
import BrandPromotions from 'app/theme/banners/BrandPromotions'
import LazyImage from 'app/theme/LazyImage'
import EventTypes from '../theme/sections/EventTypes'
import Guarantee from '../theme/sections/Guarantee'
import HowItWorksCustom from '../theme/sections/HowItWorksCustom'
import Cuisines from '../theme/sections/Cuisines'
import FindATruck from '../theme/sections/FindATruck'
import Blog from '../theme/sections/Blog'
import { useAppContext } from 'app/AppContext'
import CityAutoComplete from 'app/theme/forms/CityAutoComplete'
import LeadModal from 'app/leads/LeadModal'
import { useModalState } from 'lib/modal'
import { useSessionRecording } from 'lib/posthog'

import howItWorksLarge from 'assets/img/how-it-works-large.jpg'

import logo from 'assets/svg/logo-bg.svg'
import banner from 'assets/img/food-truck-catering.jpg'
import corporateEvent from 'assets/img/large-event-scaled.jpeg'
import campusEvent from 'assets/img/food-truck-campus-events.jpg'
import conferenceCatering from 'assets/img/conference-catering.jpg'

import illustration from 'assets/svg/truck-illustration.svg'
import donutTruck from 'assets/svg/donut-truck.svg'
import forkKnifeMarker from 'assets/svg/map-icon.svg'
import burger from 'assets/img/business-lunch-food-court-hero.jpg'
import balloonHat from 'assets/img/party-catering-1.jpg'
import tacos from 'assets/img/cuisine/taco-header.jpg'
import guarantee from 'assets/svg/guarantee.svg'
import computer from 'assets/svg/computer.svg'
import partyHat from 'assets/svg/party-hat.svg'
import graduationCap from 'assets/svg/graduation-cap.svg'
import filmCamera from 'assets/svg/film.svg'
import weddingCake from 'assets/svg/wedding-cake.svg'
import balloons from 'assets/svg/balloons.svg'
import mic from 'assets/svg/mic.svg'
import tagIcons from 'app/utils/tagIcons'

import losAngeles from 'assets/img/los-angeles.jpg'
import atlanta from 'assets/img/atlanta.jpg'
import dallas from 'assets/img/dallas.jpg'
import chicago from 'assets/img/chicago.jpg'
import sanJose from 'assets/img/san-jose.jpg'
import washington from 'assets/img/washington-dc.jpg'

import apple from 'assets/img/press/apple.png'
import toyota from 'assets/img/press/toyota.png'
import google from 'assets/img/press/google.png'
import lululemon from 'assets/img/press/lululemon.png'
import disney from 'assets/img/press/disney.png'
import nike from 'assets/img/press/nike.png'

import env from 'env'

const eventIcons = {
  'corporate': computer,
  'private': partyHat,
  'campus': graduationCap,
  'production': filmCamera,
  'wedding': weddingCake,
  'community': balloons,
}

const fetchBlogLinks = () => (
  fetch(`${env.BLOG_URL}/feed/json`).then(res => res.ok ? res.json() : null)
)

const MAX_BLOG_POSTS = 6

const EXPERIMENT_KEY = '2024_06_home_page_value_prop'
const VARIANT_KEY = 'showVariant'

export default function HomePage() {
  const [selectedCity, setSelectedCity]  = useState()
  const context = useAppContext()
  const [blogData, setBlogData] = useState(context.blogLinks)
  const { openModal, ...modalState } = useModalState()
  useSessionRecording()

  // Homepage Experiment
  const location = useLocation()
  const queryParams = useMemo(() => {
    const params = new URLSearchParams(location.search)

    return {
      homepage_experiment: params.get(EXPERIMENT_KEY),
    }
  }, [location])
  const variantKey = useFeatureFlagVariantKey(EXPERIMENT_KEY)
  const experimentEnabled = (VARIANT_KEY === variantKey) || queryParams.homepage_experiment === 'true'

  let maxBlogPostsUsed = experimentEnabled ? 3 : MAX_BLOG_POSTS

  const caterers = useQuery(['caterers', {
    sort: '-ranking',
    include: [
      'photo',
      'cities',
    ],
    filter: {
      closed: false,
    },
    fields: {
      caterers: ['name', 'slug'],
      cities: ['name'],
      photos: ['uuid', 'extension', 'path']
    },
    page: {
      size: 21
    }
  }])

  if (typeof window === 'undefined' && context.blogLinks === undefined) {
    context.promises = context.promises || []
    context.promises.push(
      fetchBlogLinks().then(data => {
        context.blogLinks = data
      }).catch(() => {
        context.blogLinks = null
      })
    )
  }

  useEffect(() => {
    if (blogData === undefined) {
      fetchBlogLinks().then(data => {
        setBlogData(data)
      }).catch(() => {})
    }
  }, [])

  let blogPosts = blogData?.items?.filter(b => b.tags.includes('Homepage')).sort((a, b) => new Date(b.date_published) - new Date(a.date_published)).slice(0, maxBlogPostsUsed)

  if (experimentEnabled) {
    return HomePageExperiment({blogPosts, modalState, openModal, selectedCity, setSelectedCity})
  }

  let foodTruckCityUrl = '/food-trucks/'

  if (selectedCity && selectedCity.urlSlug) {
    foodTruckCityUrl = `${foodTruckCityUrl}${selectedCity.url_slug}/`
  }

  return (
    <Layout>
      <Helmet>
        <title>Roaming Hunger - Food Truck Catering, Promotions and Events</title>
        <meta name="description" content="Roaming Hunger is the easiest way to find and book over 17978 food trucks for corporate catering, big events and branded promotions." />
        <meta name="og:title" content="Roaming Hunger - Food Truck Catering, Promotions and Events"></meta>
        <script type="application/ld+json">
          {JSON.stringify(googleMetaTags)}
        </script>
      </Helmet>

      <LeadModal
        {...modalState}
      />

      <div className="bg-blue">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="bg-cover bg-center h-64 lg:h-full w-full" style={{backgroundImage: `url(${banner})`}}/>
          <div className="flex flex-col justify-center bg-blue p-8 lg:p-16 max-w-xl">
            <h1 className="text-4xl lg:text-5xl font-platform text-black font-bold leading-tight">
              Hire the Best Food Trucks for Your Event
            </h1>
            <p className="text-white text-xl font-light py-4">
              Join thousands of hosts and event planners who depend upon Roaming Hunger to elevate their catering
              experience with the best food trucks, carts, and pop-ups in their city.
            </p>
            <div className="mt-4">
              <a
                href="/food-truck-catering/"
                className="btn btn-white text-blue border-0 px-4 py-2 rounded-full inline-block">
                Book food truck catering {`>`}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-16">
        <div className="container max-w-5xl">
          <h2 className="text-2xl md:text-4xl font-light md:font-normal text-black font-platform text-left lg:text-center">
            You're In Great Company.
          </h2>
          <p className="text-black text-xl text-left lg:text-center font-light py-4">
            20,000,000+ happy eaters (and counting) and thousands of companies served since 2009.
          </p>
          <div className="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 md:gap-12">
            <img src={apple} alt="Apple logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={nike} alt="Nike logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={disney} alt="Disney logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={lululemon} alt="Lululemon logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={toyota} alt="Toyota logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={google} alt="Google logo" className="m-auto h-12 md:h-14 w-auto"/>
          </div>
        </div>
      </div>
      <div className="bg-white py-16">
        <div className="container max-w-5xl">
          <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
            Your Next Event Starts Here
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
            <a href="/food-truck-catering/corporate-catering/" className='flex flex-col'>
              <div className='flex-1'>
                <div className="bg-cover h-0" alt="" style={{paddingTop:"60%", backgroundImage: `url(${corporateEvent})`, borderRadius: "1.5rem"}}/>
                <p className="text-2xl text-black font-bold my-2">Corporate Events</p>
                <p className="text-md font-light my-2">
                  Bring the most delicious, gourmet food trucks to your next employee or client appreciation day, milestone celebration, or company event.
                </p>
              </div>
              <p children="Learn More >" className="text-blue text-md" />
            </a>
            <a href="/food-truck-catering/campus-catering/" className='flex flex-col'>
              <div className='flex-1'>
                <div className="bg-cover h-0" alt="" style={{paddingTop:"60%", backgroundImage: `url(${campusEvent})`, borderRadius: "1.5rem"}}/>
                <p className="text-2xl text-black font-bold my-2">Campus Events</p>
                <p className="text-md font-light my-2">
                  Looking to feed your faculty or the entire student body? Whatever your occasion, it’s easy to bring food trucks to your school or university.
                </p>
              </div>
              <p children="Learn More >" className="text-blue text-md" />
            </a>
            <a href="/food-truck-catering/conference-and-event-catering/" className='flex flex-col'>
              <div className='flex-1'>
                <div className="bg-cover h-0" alt="" style={{paddingTop:"60%", backgroundImage: `url(${conferenceCatering})`, borderRadius: "1.5rem"}}/>
                <p className="text-2xl text-black font-bold my-2">Conferences & Conventions</p>
                <p className="text-md font-light my-2">
                Whether you’re expecting 500 or 50,000 people, we can build out a custom food service solution based on your individual needs.
                </p>
              </div>
              <p children="Learn More >" className="text-blue text-md" />
            </a>
          </div>
        </div>
      </div>
      <div className="bg-blue-100 py-16">
        <div className="container max-w-5xl">
          <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
            Find food truck catering in your city
          </h2>
          <p className="text-black text-xl text-left lg:text-center font-light py-4">
            Fast, easy and delicious food trucks you can book near you.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
          <a href='/los-angeles-ca/food-truck-catering/'>
            <div
              className="bg-cover h-0"
              alt=""
              style={{
                paddingTop:"50%",
                backgroundImage: `url(${losAngeles})`,
                borderTopRightRadius: "1.5rem",
                borderTopLeftRadius: "1.5rem"
              }}
            />
            <div
              className="bg-white text-center"
              style={{
                borderBottomRightRadius: "1.5rem",
                borderBottomLeftRadius: "1.5rem"
              }}
            >
              <p className="text-2xl text-black font-bold py-2">Los Angeles</p>
              <div className="flex justify-between p-6 pt-0 text-black">
                <p className="text-md font-light">
                  408k+ Eaters
                </p>
                <p className="text-md font-light">
                  1160 Food Trucks
                </p>
              </div>
            </div>
          </a>
          <a href='/san-jose-ca/food-truck-catering/'>
            <div
              className="bg-cover h-0"
              alt=""
              style={{
                paddingTop:"50%",
                backgroundImage: `url(${sanJose})`,
                borderTopRightRadius: "1.5rem",
                borderTopLeftRadius: "1.5rem"
              }}
            />
            <div
              className="bg-white text-center"
              style={{
                borderBottomRightRadius: "1.5rem",
                borderBottomLeftRadius: "1.5rem"
              }}
            >
              <p className="text-2xl text-black font-bold py-2">San Jose</p>
              <div className="flex justify-between p-6 pt-0 text-black">
                <p className="text-md font-light">
                  109k+ Eaters
                </p>
                <p className="text-md font-light">
                  243 Food Trucks
                </p>
              </div>
            </div>
          </a>
          <a href='/chicago-il/food-truck-catering/'>
              <div
                className="bg-cover h-0"
                alt=""
                style={{
                  paddingTop:"50%",
                  backgroundImage: `url(${chicago})`,
                  borderTopRightRadius: "1.5rem",
                  borderTopLeftRadius: "1.5rem"
                }}
              />
              <div
                className="bg-white text-center"
                style={{
                  borderBottomRightRadius: "1.5rem",
                  borderBottomLeftRadius: "1.5rem"
                }}
              >
                <p className="text-2xl text-black font-bold py-2">Chicago</p>
                <div className="flex justify-between p-6 pt-0 text-black">
                  <p className="text-md font-light">
                    85k+ Eaters
                  </p>
                  <p className="text-md font-light">
                    276 Food Trucks
                  </p>
                </div>
              </div>
            </a>
            <a href='/atlanta-ga/food-truck-catering/'>
              <div
                className="bg-cover h-0"
                alt=""
                style={{
                  paddingTop:"50%",
                  backgroundImage: `url(${atlanta})`,
                  borderTopRightRadius: "1.5rem",
                  borderTopLeftRadius: "1.5rem"
                }}
              />
              <div
                className="bg-white text-center"
                style={{
                  borderBottomRightRadius: "1.5rem",
                  borderBottomLeftRadius: "1.5rem"
                }}
              >
                <p className="text-2xl text-black font-bold py-2">Atlanta</p>
                <div className="flex justify-between p-6 pt-0 text-black">
                  <p className="text-md font-light">
                    111k+ Eaters
                  </p>
                  <p className="text-md font-light">
                    417 Food Trucks
                  </p>
                </div>
              </div>
            </a>
            <a href='/washington-dc/food-truck-catering/'>
              <div
                className="bg-cover h-0"
                alt=""
                style={{
                  paddingTop:"50%",
                  backgroundImage: `url(${washington})`,
                  borderTopRightRadius: "1.5rem",
                  borderTopLeftRadius: "1.5rem"
                }}
              />
              <div
                className="bg-white text-center"
                style={{
                  borderBottomRightRadius: "1.5rem",
                  borderBottomLeftRadius: "1.5rem"
                }}
              >
                <p className="text-2xl text-black font-bold py-2">Washington, D.C.</p>
                <div className="flex justify-between p-6 pt-0 text-black">
                  <p className="text-md font-light">
                    119k+ Eaters
                  </p>
                  <p className="text-md font-light">
                    297 Food Trucks
                  </p>
                </div>
              </div>
            </a>
            <a href='/dallas-tx/food-truck-catering/'>
              <div
                className="bg-cover h-0"
                alt=""
                style={{
                  paddingTop:"50%",
                  backgroundImage: `url(${dallas})`,
                  borderTopRightRadius: "1.5rem",
                  borderTopLeftRadius: "1.5rem"
                }}
              />
              <div
                className="bg-white text-center"
                style={{
                  borderBottomRightRadius: "1.5rem",
                  borderBottomLeftRadius: "1.5rem"
                }}
              >
                <p className="text-2xl text-black font-bold py-2">Dallas</p>
                <div className="flex justify-between p-6 pt-0 text-black">
                  <p className="text-md font-light">
                    104k+ Eaters
                  </p>
                  <p className="text-md font-light">
                    419 Food Trucks
                  </p>
                </div>
              </div>
            </a>
          </div>
          {/* <div className="mt-4 text-center">
            <a
              className="btn btn-blue border-0 px-4 py-2 mt-10 rounded-full"
              href="/food-trucks/"
            >
              Find Trucks in Your City {`>`}
            </a>
          </div> */}
          <div className="container flex flex-col items-center justify-center text-center my-10">
            <p className="text-gray-800 text-2xl font-light">
              Find and book food trucks, trailers, and carts in your city.
            </p>
            <div className="flex mt-5 shadow-lg bg-white border border-gray-400" >
              <CityAutoComplete
                value={selectedCity}
                onChange={city => {
                  setSelectedCity(city)
                }}
              />
              <a
                href={foodTruckCityUrl}
                className={["btn px-4 py-2 text-lg border-0", (selectedCity ? "btn-blue" : "btn-gray pointer-events-none")]}
                onClick={() => {
                  // TODO: Show faux-error message
                }}
                children="Go →"
              />
            </div>

            <p className="text-gray-600 text-lg font-light mt-5">
              Or browse our <a className="text-blue underline" href="/food-trucks/">full list of cities</a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-green py-16">
        <div className="container max-w-5xl flex flex-col lg:flex-row">
          <div className="flex justify-center px-16">
            <img src={guarantee} alt="An illustration of a certified badge" className="w-24" />
          </div>
          <div className="flex-1">
            <p className="text-2xl font-platform text-white text-xl font-light py-4">
              Why Work With Us?
            </p>
            <h2 className="text-5xl font-platform text-white font-bold leading-tight">
              100% Roaming Hunger Service Guarantee.
            </h2>
            <p className="text-white text-xl font-light py-4">
              We guarantee every catering, every time. Because food just tastes better when you have peace of mind. For more details,&nbsp;
              <a href="/food-truck-catering/roaming-hunger-guarantee/" className='underline font-bold'>click here.</a>
            </p>
            <div className="mt-4">
              <a href="/food-truck-catering/" className="btn btn-white text-blue border-0 px-4 py-2 rounded-full inline-block">
                Get Started With Catering {`>`}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-16">
        <div className="container max-w-5xl">
          <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
            Food Truck Catering For Any Event
          </h2>
          <p className="text-black text-xl text-left lg:text-center font-light py-4">
            From food service at a corporate park to an intimate backyard celebration,
            find and book the perfect food truck, cart, or pop-up.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.corporate} alt="An illustration of a computer" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/corporate-catering/" className="text-2xl text-black font-bold my-2">
                  Corporate Catering
                </a>
                <p className="text-md font-light my-2">
                  Company Events, Catered Lunch Programs, Employee & Client Appreciations, Meal Programs, Business Lunches
                </p>
              </div>
              <a href="/food-truck-catering/corporate-catering/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.private} alt="An illustration of a party hat" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/party-catering/" className="text-2xl text-black font-bold my-2">
                  Private Parties
                </a>
                <p className="text-md font-light my-2">
                  Birthday, Holiday, Graduation, Anniversary, Bar/Bat Mitzvahs, Baby Shower, House Warming
                </p>
              </div>
              <a href="/food-truck-catering/party-catering/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.campus} alt="An illustration of a graduation cap" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/campus-catering/" className="text-2xl text-black font-bold my-2">
                  University & School Events
                </a>
                <p className="text-md font-light my-2">
                  On/Off-Campus Events, Faculty & Alumni Events, Recruitment Events, School Fundraisers, Sports Events
                </p>
              </div>
              <a href="/food-truck-catering/campus-catering/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.production} alt="An illustration of a film camera" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/film-production-catering/" className="text-2xl text-black font-bold my-2">
                  Production Catering
                </a>
                <p className="text-md font-light my-2">
                  Movie Set Catering, TV and Film Catering, 24 Hour Production Catering, On Location Catering, Photo Shoots
                </p>
              </div>
              <a href="/food-truck-catering/film-production-catering/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.wedding} alt="An illustration of a wedding cake" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/food-truck-wedding/" className="text-2xl text-black font-bold my-2">
                  Wedding Catering
                </a>
                <p className="text-md font-light my-2">
                  Wedding Receptions, Rehearsal Dinners, Late Night Snacks, Day-After Brunches
                </p>
              </div>
              <a href="/food-truck-catering/food-truck-wedding/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.community} alt="An illustration of balloons" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/local-community-concessions/" className="text-2xl text-black font-bold my-2">
                  Community Events
                </a>
                <p className="text-md font-light my-2">
                  Street Fairs, Neighborhood Block Parties, Flea Markets, Little League Games, ...
                </p>
              </div>
              <a href="/food-truck-catering/local-community-concessions/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.community} alt="An illustration of balloons" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/large-event-food-concessions/" className="text-2xl text-black font-bold my-2">
                  Large-Scale Events & Multi-City Events
                </a>
                <p className="text-md font-light my-2">
                  Large Company Events, Sporting Events, Music Festivals, National Event Series, Tours
                </p>
              </div>
              <a href="/food-truck-catering/large-event-food-concessions/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="bg-gray-200 text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <img src={eventIcons.community} alt="An illustration of balloons" className="m-auto h-20 w-auto"/>
                <a href="/food-truck-catering/conference-and-event-catering/" className="text-2xl text-black font-bold my-2">
                  Conferences & Conventions
                </a>
                <p className="text-md font-light my-2">
                  Expos, Professional Events, Industry Events, and Marketing Events
                </p>
              </div>
              <a href="/food-truck-catering/conference-and-event-catering/" children="Explore >" className="text-blue text-md" />
            </div>
            <div className="text-center p-5 flex flex-col" style={{borderRadius: "1.5rem"}}>
              <div className="flex-1">
                <p className="text-2xl text-black font-bold my-2">
                  If you have a different type of event we can still help
                </p>
                <p className="text-md font-light my-2">
                  Whatever the occasion we can help you throw an amazing event.

                </p>
              </div>
              <button
                children="Get Started"
                onClick={openModal}
                className="btn btn-blue border-0 m-auto px-4 py-2 rounded-full block"
              />
            </div>

          </div>
        </div>
      </div>
      <div className="bg-gray-200 py-16">
        <div className="container max-w-5xl">
          <h2 className="text-4xl font-platform text-center text-black font-bold leading-tight">
            Most Popular Cuisines
          </h2>

          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 lg:gap-12 mt-10">
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.tacos} alt="An illustration of a taco" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Tacos</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.sandwiches} alt="An illustration of a sandwich" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Sandwiches</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.burgers} alt="An illustration of a hamburger" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Burgers</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.dessert} alt="An illustration of an ice cream cone" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Desserts</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.bbq} alt="An illustration of a hotdog" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">BBQ</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.breakfast} alt="An illustration of a muffin" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Breakfast</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.pizza} alt="An illustration of slice of pizza" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Pizza</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons['healthy-food']} alt="An illustration of carrots" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Healthy</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.mediterranean} alt="An illustration of a gyro" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Mediterranean</p>
            </div>
            <div className="bg-white text-center px-5 pt-2" style={{borderRadius: "1.5rem"}}>
              <img src={tagIcons.asian} alt="An illustration of a sushi" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Asian</p>
            </div>
          </div>

          {/* Note: hiding this until product decides what to do abt these links */}
          <div className="mt-10 hidden">
            <button className="btn btn-blue border-0 m-auto px-4 py-2 rounded-full block">
              View All Cuisine Types {`>`}
            </button>
          </div>
        </div>
      </div>
      {blogPosts && (
        <div className="bg-white py-16">
          <div className="container max-w-5xl">
            <h2 className="text-4xl font-platform text-left lg:text-center text-black font-bold leading-tight">
              Roaming Hunger Blog
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-12 mt-10">
              {blogPosts.map(item => (
                <div key={item.id} className="flex flex-col">
                  <div className="flex-1">
                    <div className="bg-cover h-0" alt="" style={{paddingTop:"60%", backgroundImage: `url(${item.image || burger})`, borderRadius: "1.5rem"}}/>
                    <a className="text-xl text-black font-bold my-2" href={item.url} dangerouslySetInnerHTML={{ __html: item.title }} />
                  </div>
                  <div>
                    <p className="text-md font-light my-2">
                      {format(new Date(item.date_modified), 'MMMM do, yyyy')}
                    </p>
                    <a href={item.url} children="Read More >" className="text-blue text-md" />
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-10 text-center">
              <a href={env.BLOG_URL} className="btn btn-blue border-0 px-4 py-2 rounded-full ">
                Check Out More Articles {`>`}
              </a>
            </div>
          </div>
        </div>
      )}
      <div style={{
        background: "linear-gradient(45deg, #00ACEC 1%, #00ACEC 50%, #00BA89 51%, #00BA89 100%)"
      }}>
        <div className="container p-0 max-w-5xl">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-0">

            <div className='bg-blue text-white text-center p-5 flex flex-col'>
              <div className="flex-1">
                <LazyImage src={forkKnifeMarker} alt="Food Truck Catering" className="m-auto h-20 w-auto" />
                <h3 className="text-white font-platform text-3xl mb-5">
                  Food Truck Catering
                </h3>
                <p className="font-light leading-tight text-xl mt-5">
                  Food truck catering for your next event. Protected by The 100% Roaming Hunger Guarantee.
                </p>
              </div>
              <div className="mt-10 text-center">
                <a href="/food-truck-catering/" className="btn btn-white text-blue border-0 px-4 py-2 rounded-full">
                  Hire A Food Truck {`>`}
                </a>
              </div>
            </div>

            <div className='bg-red text-white text-center p-5 flex flex-col'>
              <div className="flex-1">
                <LazyImage src={mic} alt="Food Truck Catering" className="m-auto h-20 w-auto" />
                <h3 className="text-white font-platform text-3xl mb-5">
                  Brand Promotions
                </h3>
                <p className="font-light leading-tight text-xl mt-5">
                  Launch pop-up experiential marketing campaigns that go anywhere, and satisfy customers’ appetites.
                </p>
              </div>
              <div className="mt-10 text-center">
                <a href="/experiential-marketing-agency/" className="btn btn-white text-blue border-0 px-4 py-2 rounded-full">
                  Promote Your Brand {`>`}
                </a>
              </div>
            </div>

            <div className='bg-green text-white text-center p-5 flex flex-col'>
              <div className="flex-1">
                <LazyImage src={donutTruck} alt="Food Truck Catering" className="m-auto h-20 w-auto" />
                <h3 className="text-white font-platform text-3xl mb-5">
                  Start a Food Truck
                </h3>
                <p className="font-light leading-tight text-xl mt-5">
                  The one-stop spot for launching your food truck business from scratch or selling your food truck.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  )
}

function HomePageExperiment({blogPosts, modalState, openModal, selectedCity, setSelectedCity}) {
  return (
    <Layout>
      <Helmet>
        <title>Roaming Hunger - Food Truck Catering, Promotions and Events</title>
        <meta name="description" content="Roaming Hunger is the easiest way to find and book over 17978 food trucks for corporate catering, big events and branded promotions." />
        <meta name="og:title" content="Roaming Hunger - Food Truck Catering, Promotions and Events"></meta>
        <script type="application/ld+json">
          {JSON.stringify(googleMetaTags)}
        </script>
      </Helmet>

      <LeadModal
        {...modalState}
      />

      <div className="bg-blue">
        <div className="xl3:max-w-6xl mx-auto grid grid-cols-1 lg:grid-cols-2">
          <div className="bg-cover bg-center h-64 lg:h-full w-full" style={{backgroundImage: `url(${banner})`}}/>
          <div className="flex flex-col justify-center bg-blue p-8 lg:p-16 max-w-xl">
            <h1 className="text-4xl lg:text-5xl font-platform text-black font-bold leading-tight">
              The Best Food Trucks Make The Best Events
            </h1>
            <p className="text-white text-xl font-light py-4">
              Your <strong className='strong'>peace of mind</strong> is our top priority — we take care of all the tough stuff, 
              so you can enjoy an amazing event.
            </p>
            <div className="mt-4">
              <a
                href="/food-truck-catering/"
                className="btn btn-white text-blue border-0 px-4 py-2 rounded-full inline-block">
                Book Food Truck Catering {`>`}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-16">
        <div className="container max-w-5xl">
          <h2 className="text-2xl md:text-4xl font-light md:font-normal text-black font-platform text-left lg:text-center">
            You're in Great Company.
          </h2>
          <p className="text-black text-xl text-left lg:text-center font-light py-4">
            20 million eaters can’t be wrong! Let us help you create an unforgettable food truck experience. 
          </p>
          <div className="mt-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6 md:gap-12">
            <img src={apple} alt="Apple logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={nike} alt="Nike logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={disney} alt="Disney logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={lululemon} alt="Lululemon logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={toyota} alt="Toyota logo" className="m-auto h-12 md:h-14 w-auto"/>
            <img src={google} alt="Google logo" className="m-auto h-12 md:h-14 w-auto"/>
          </div>
        </div>
      </div>
      <HowItWorksCustom
        buttonUrl='/food-truck-catering/'
        description={<p className='font-light text-xl'>Welcome to the easiest catering experience...ever. From food trucks and trailers, to pop-ups and carts, Roaming Hunger helps you create amazing events <strong className='font-bold'>anywhere, anytime</strong>.</p>}
        heading='How to Book with Us'
        hiddenLg={false}
        image={{
          url: howItWorksLarge,
          altText: 'People enjoying a food truck meal'
        }}
      />
      <Guarantee
        buttonUrl='/food-truck-catering/'
        items={null}
      />
      <EventTypes openModal={openModal} />
      <Cuisines />
      <FindATruck />
      {blogPosts && <Blog posts={blogPosts} />}
    </Layout>
  )
}
