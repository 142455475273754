import React from 'react'
import tagIcons from 'app/utils/tagIcons'

export default function Cuisines() {
    return (
      <div className="bg-gray-200 py-16">
        <div className="container max-w-5xl">
          <h2 className="text-4xl font-platform text-center text-black font-bold leading-tight">
            Your Favorite Foods, All in One Place
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 lg:gap-12 mt-10">
            <a 
              href="https://roaminghunger.com/food-truck-catering/taco-truck-catering/"
              className="bg-white hover:bg-gray-400 text-center px-5 pt-2"
              style={{borderRadius: "1.5rem"}}
              >
              <img src={tagIcons.tacos} alt="An illustration of a taco" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Tacos</p>
            </a>
            <a 
              href="https://roaminghunger.com/food-truck-catering/burger-catering-truck/"
              className="bg-white hover:bg-gray-400 text-center px-5 pt-2"
              style={{borderRadius: "1.5rem"}}
              >
              <img src={tagIcons.burgers} alt="An illustration of a hamburger" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Burgers</p>
            </a>
            <a 
              href="https://roaminghunger.com/food-truck-catering/bbq-food-truck-catering/"
              className="bg-white hover:bg-gray-400 text-center px-5 pt-2"
              style={{borderRadius: "1.5rem"}}
              >
              <img src={tagIcons.bbq} alt="An illustration of a hotdog" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">BBQ</p>
            </a>
            <a 
              href="https://roaminghunger.com/food-truck-catering/pizza-truck-catering/"
              className="bg-white hover:bg-gray-400 text-center px-5 pt-2"
              style={{borderRadius: "1.5rem"}}
              >
              <img src={tagIcons.pizza} alt="An illustration of slice of pizza" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Pizza</p>
            </a>
            <a 
              href="https://roaminghunger.com/food-truck-catering/ice-cream-truck-catering/"
              className="bg-white hover:bg-gray-400 text-center px-5 pt-2"
              style={{borderRadius: "1.5rem"}}
              >
              <img src={tagIcons.dessert} alt="An illustration of an ice cream cone" className="m-auto h-12 w-auto"/>
              <p className="text-lg text-black font-bold my-2">Ice Cream</p>
            </a>
          </div>
        </div>
      </div>
    )
}